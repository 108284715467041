<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>共同基金管理</el-breadcrumb-item>
      <el-breadcrumb-item>页面管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <!-- 使用24分栏进行布局 -->
        <el-col :span="8">
          <!-- clearable为清空按钮 -->
          <!-- clear为点击清空按钮后要实现的函数 -->
          <el-input placeholder="请输入内容" v-model="queryInfo.title" clearable @clear="getPageList">
            <el-button slot="append" icon="el-icon-search" @click="getPageList">搜索</el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加页面</el-button>
        </el-col>
      </el-row>
      <el-card class="main-card">
        <el-card class="page-card" v-for="item in pageList" :key="item.id">
          <h1>{{item.title}}</h1>
          <span>id: {{item.id}}</span>
          <span>类型:{{item.type}}</span>
          <div>
            <el-button type="primary" icon="el-icon-edit" circle @click="showEditDialog(item.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" circle @click="removePageById(item.id)"></el-button>
          </div>
        </el-card>
      </el-card>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <el-dialog title="添加页面" :visible.sync="addDialogVisible" width="70%" @close="addDialogClosed">
      <!-- 主题内容区域 -->
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="addForm.type" placeholder="请选择页面类型">
            <el-option label="关于我们" value="关于我们"></el-option>
            <el-option label="基金报告" value="基金报告"></el-option>
            <el-option label="新闻中心" value="新闻中心"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章" prop="content">
          <div>{{addForm.content}}</div>
          <el-table :data="articleList" border stripe>
            <!-- 添加索引列 -->
            <el-table-column label="id" prop="id" width="60px"></el-table-column>
            <el-table-column label="标题" prop="title"></el-table-column>
            <el-table-column label="查看">
              <template slot-scope="scope">
                <el-button type="info" icon="el-icon-zoom-in" size="mini" @click="showArticleDialog(scope.row.id)"></el-button>
              </template>
            </el-table-column>
            <el-table-column label="类型" prop="type"></el-table-column>
            <el-table-column label="创建时间" prop="create_at"></el-table-column>
            <el-table-column label="更新时间" prop="update_at"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="success" icon="el-icon-check" circle @click="addForm_addArticle(scope.row.id)"></el-button>
                <el-button type="danger" icon="el-icon-delete" circle @click="addForm_deleteArticle(scope.row.id)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPage">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改页面" :visible.sync="editDialogVisible" width="70%" @close="editDialogClosed">
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="editForm.type" placeholder="请选择页面类型">
            <el-option label="关于我们" value="关于我们"></el-option>
            <el-option label="基金报告" value="基金报告"></el-option>
            <el-option label="新闻中心" value="新闻中心"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文章" prop="content">
          <div>{{editForm.content}}</div>
          <el-table :data="articleList" border stripe>
            <!-- 添加索引列 -->
            <el-table-column label="id" prop="id" width="60px"></el-table-column>
            <el-table-column label="标题" prop="title"></el-table-column>
            <el-table-column label="查看">
              <template slot-scope="scope">
                <el-button type="info" icon="el-icon-zoom-in" size="mini" @click="showArticleDialog(scope.row.id)"></el-button>
              </template>
            </el-table-column>
            <el-table-column label="类型" prop="type"></el-table-column>
            <el-table-column label="创建时间" prop="create_at"></el-table-column>
            <el-table-column label="更新时间" prop="update_at"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="success" icon="el-icon-check" circle @click="editForm_addArticle(scope.row.id)"></el-button>
                <el-button type="danger" icon="el-icon-delete" circle @click="editForm_deleteArticle(scope.row.id)"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editArticleInfo">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看文章" :visible.sync="articleDialogVisible" width="70%" class="articleDialog">
      <!-- 主题内容区域 -->
      <h1 v-html="articleForm.title"></h1>
      <!-- <div v-html="editForm.content"></div> -->
      <div v-html="$xss(articleForm.content)"></div>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="articleDialogVisible=false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queryInfo: {
        title: '',
        type: '',
        pagenum: 1,
        pagesize: 5,
      },
      articleList: [],
      pageList: [],
      total: 0,
      addDialogVisible: false,
      addForm: {
        title: '',
        type: '',
        content: [],
        website: 'gongtong',
      },
      addFormRules: {
        title: [{ required: true, message: '请输入页面标题', trigger: 'blur' }],
        type: [{ required: true, message: '请输入页面类型', trigger: 'blur' }],
      },
      editForm: {},
      editDialogVisible: false,
      articleForm: {},
      articleDialogVisible: false,
      addDialogVisible: false,
      editFormRules: {
        title: [{ required: true, message: '请输入页面标题', trigger: 'blur' }],
        type: [{ required: true, message: '请输入页面类型', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.getArticleList()
    this.getPageList()
  },
  methods: {
    async getArticleList() {
      const res = await this.$http.get('/gongtong/get-all-articles')
      this.articleList = res.data
    },
    async getPageList() {
      const res = await this.$http.get('/gongtong/get-pages', {
        params: this.queryInfo,
      })
      this.pageList = res.data.data
      const total_server = await this.$http.get('/gongtong/get-pages-count')
      this.total = total_server.data.data[0]['COUNT(*)']
    },
    handleSizeChange(newSize) {
      //当每页最多显示的数据数表单更改时，其值(作为参数传给服务器)也要跟着改
      this.queryInfo.pagesize = newSize
      //重新向服务器发送新的参数
      this.getPageList()
    },
    handleCurrentChange(newPage) {
      //及时更改要获取的页数
      this.queryInfo.pagenum = newPage
      this.getPageList()
    },
    addDialogClosed() {
      //对话框在关闭时清空表单，防止下一次打开时还留有上一次的记录
      this.$refs.addFormRef.resetFields()
    },
    addForm_addArticle(id) {
      for (var i = 0; i < this.addForm.content.length; i++) {
        if (this.addForm.content[i] == id) {
          return
        }
      }
      this.addForm.content.push(id)
    },
    addForm_deleteArticle(id) {
      for (var i = 0; i < this.addForm.content.length; i++) {
        if (this.addForm.content[i] == id) {
          this.addForm.content.splice(i, 1)
        }
      }
    },
    addPage() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        this.addForm.content = this.addForm.content + ''
        const { data: res } = await this.$http.post(
          '/gongtong/add-page',
          this.addForm
        )
        if (res.meta.status !== 201) {
          this.$message.error('添加页面失败!')
        }
        this.$message.success('添加页面成功!')
        this.addDialogVisible = false
        this.getPageList()
      })
    },
    async showArticleDialog(id) {
      const res = await this.$http.get('/gongtong/show-edit-article/' + id)
      if (res.status !== 200) {
        return this.$message.error('查询文章信息失败!')
      }
      this.articleForm = res.data.data[0]
      this.articleDialogVisible = true
    },
    async showEditDialog(id) {
      const res = await this.$http.get('/gongtong/show-edit-page/' + id)
      if (res.status !== 200) {
        return this.$message.error('查询页面信息失败!')
      }
      this.editForm = res.data.data[0]
      this.editForm.content = this.editForm.content.split(',')
      this.editDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    editForm_addArticle(id) {
      for (var i = 0; i < this.editForm.content.length; i++) {
        if (this.editForm.content[i] == id) {
          return
        }
      }
      this.editForm.content.push(id)
    },
    editForm_deleteArticle(id) {
      for (var i = 0; i < this.editForm.content.length; i++) {
        if (this.editForm.content[i] == id) {
          this.editForm.content.splice(i, 1)
        }
      }
    },
    editArticleInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        const res = await this.$http.put(
          '/gongtong/edit-page/' + this.editForm.id,
          {
            title: this.editForm.title,
            content: this.editForm.content + '',
            type: this.editForm.type,
          }
        )
        if (res.status !== 200) {
          return this.$message.error('修改页面信息失败!')
        }

        this.$message.success('修改页面信息成功!')
        this.editDialogVisible = false
        this.getPageList()
      })
    },
    async removePageById(id) {
      const confirmResult = await this.$confirm(
        //若点确定，confirmResult为：字符串confirm，若点取消，confirmResult为：字符串cancle
        '此操作将永久删除该页面, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err) //此操作将错误值也返回给confirmResult

      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const res = await this.$http.delete('/gongtong/delete-page/' + id)
      if (res.status !== 200) {
        return this.$message.error('删除页面失败!')
      }
      this.$message.success('删除页面成功!')
      this.getPageList()
    },
  },
}
</script>
<style lang="less" scoped>
.main-card {
  display: block;
  margin-top: 20px;
  padding-bottom: 20px;
}
.page-card {
  display: block;
  margin: 10px 16px;
  float: left;
  width: 17%;
  height: 300px;
  min-width: 200px;
}

.page-card h1 {
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  font-size: 30px;
  overflow: hidden;
}

.page-card span {
  display: block;
  margin: 20px auto;
  width: 80%;
  text-align: center;
  color: gray;
  font-size: 14px;
}

.page-card div {
  display: flex;
  margin: 0 auto;
  margin-top: 32%;
  width: 80%;
  justify-content: center;
}

.page-card div .el-button {
  margin: 0 20px;
}

.el-table {
  height: 200px;
  overflow-y: scroll;
}
.articleDialog {
  h1 {
    text-align: center;
  }
  line-height: 1.5;
}
</style>