<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>共同基金管理</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <!-- 使用24分栏进行布局 -->
        <el-col :span="8">
          <!-- clearable为清空按钮 -->
          <!-- clear为点击清空按钮后要实现的函数 -->
          <el-input placeholder="请输入内容" v-model="queryInfo.title" clearable @clear="getArticleList">
            <el-button slot="append" icon="el-icon-search" @click="getArticleList">搜索</el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加文章</el-button>
        </el-col>
        <el-dropdown @command="dropdownCommand" trigger="click">
          <span class="el-dropdown-link">{{filter_method}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="default">默认排序</el-dropdown-item>
            <el-dropdown-item command="up">按时间从旧到新排序</el-dropdown-item>
            <el-dropdown-item command="down">按时间从新到旧排序</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown @command="handleDropDownCommand" trigger="click" placement="bottom">
          <span class="el-dropdown-link">
            {{filter_type}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu class="dropdown-type-menu" slot="dropdown">
            <el-dropdown-item command="default">&emsp;</el-dropdown-item>
            <el-dropdown-item command="关于我们">关于我们</el-dropdown-item>
            <el-dropdown-item command="基金报告">基金报告</el-dropdown-item>
            <el-dropdown-item command="新闻中心">新闻中心</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
      <el-table :data="articleList" border stripe>
        <!-- 添加索引列 -->
        <el-table-column label="id" prop="id" width="60px"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="简介" prop="description"></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="创建时间" prop="create_at"></el-table-column>
        <el-table-column label="更新时间" prop="update_at"></el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button type="info" icon="el-icon-zoom-in" size="mini" @click="showArticleDialog(scope.row.id)"></el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeArticleById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[10,15,30]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <el-dialog title="添加文章" :visible.sync="addDialogVisible" width="70%" @close="addDialogClosed">
      <!-- 主题内容区域 -->
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="description">
          <el-input v-model="addForm.description"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="addForm.type" placeholder="请选择文章类型">
            <el-option label="关于我们" value="关于我们"></el-option>
            <el-option label="新闻中心" value="新闻中心"></el-option>
            <el-option label="基金报告" value="基金报告"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="正文" prop="content">
          <quillEditor ref="addText" v-model="addForm.content" :options="editorOption1"></quillEditor>
          <input style="display: none" id="fileInput" type="file" name="file" @change="uploadFile($event,1)" />
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addArticle">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改文章" :visible.sync="editDialogVisible" width="70%" @close="editDialogClosed">
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="introduction">
          <el-input v-model="editForm.description"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="editForm.type" placeholder="请选择文章类型">
            <el-option label="关于我们" value="关于我们"></el-option>
            <el-option label="新闻中心" value="新闻中心"></el-option>
            <el-option label="基金报告" value="基金报告"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="正文" prop="text">
          <quillEditor ref="editText" v-model="editForm.content" :options="editorOption2"></quillEditor>
          <input style="display: none" id="fileInput2" type="file" name="file" @change="uploadFile($event,2)" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editArticleInfo">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看文章" :visible.sync="articleDialogVisible" width="70%" class="articleDialog">
      <!-- 主题内容区域 -->
      <h1 v-html="editForm.title"></h1>
      <div v-html="$xss(editForm.content)"></div>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="articleDialogVisible=false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  data() {
    return {
      queryInfo: {
        title: '',
        type: '',
        pagenum: 1,
        pagesize: 15,
        sort: 'default',
      },
      articleList: [],
      total: 0,
      filter_method: '排序方式',
      filter_type: '文章类型',
      addDialogVisible: false,
      addForm: {
        title: '',
        description: '',
        type: '',
        content: '',
      },
      addFormRules: {
        title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
        description: [
          { required: false, message: '请输入文章简介', trigger: 'blur' },
        ],
        type: [{ required: true, message: '请输入文章类型', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入文章正文', trigger: 'blur' },
        ],
      },
      editForm: {},
      editDialogVisible: false,
      articleDialogVisible: false,
      editFormRules: {
        title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
        description: [
          { required: false, message: '请输入文章简介', trigger: 'blur' },
        ],
        type: [{ required: true, message: '请输入文章类型', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入文章正文', trigger: 'blur' },
        ],
      },
      editorOption1: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
              ['blockquote', 'code-block'],     //引用，代码块
              [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],     //列表
              [{ 'script': 'sub' }, { 'script': 'super' }],   // 上下标
              [{ 'indent': '-1' }, { 'indent': '+1' }],     // 缩进
              [{ 'direction': 'rtl' }],             // 文本方向
              [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              [{ 'font': [] }],     //字体
              [{ 'align': [] }],    //对齐方式
              ['clean'],    //清除字体样式
              ['link', 'image', 'video']    //上传图片、上传视频
            ],
            handlers: {
              link: value => { //编辑器-上传文件
                if (value) {
                  document.querySelector('#fileInput').click()
                }
              }
            }
          }
        }
      },
      editorOption2: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],    //加粗，斜体，下划线，删除线
              ['blockquote', 'code-block'],     //引用，代码块
              [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],     //列表
              [{ 'script': 'sub' }, { 'script': 'super' }],   // 上下标
              [{ 'indent': '-1' }, { 'indent': '+1' }],     // 缩进
              [{ 'direction': 'rtl' }],             // 文本方向
              [{ 'size': ['small', false, 'large', 'huge'] }], // 字体大小
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
              [{ 'color': [] }, { 'background': [] }],     // 字体颜色，字体背景颜色
              [{ 'font': [] }],     //字体
              [{ 'align': [] }],    //对齐方式
              ['clean'],    //清除字体样式
              ['link', 'image', 'video']    //上传图片、上传视频
            ],
            handlers: {
              link: value => { //编辑器-上传文件
                if (value) {
                  document.querySelector('#fileInput2').click()
                }
              }
            }
          }
        }
      }
    }
  },
  created() {
    this.getArticleList()
  },
  methods: {
    async getArticleList() {
      const res = await this.$http.get('/gongtong/get-articles-by-time', {
        params: this.queryInfo,
      })
      this.articleList = res.data.data
      const total_server = await this.$http.get('/gongtong/get-articles-count', {
        params: {
          title: this.queryInfo.title,
          type: this.queryInfo.type,
        },
      })
      this.total = total_server.data.data[0]['COUNT(*)']
    },
    dropdownCommand(command) {
      this.queryInfo.pagenum = 1
      this.queryInfo.sort = command
      if (command == 'default')
        this.filter_method = '默认排序'
      if (command == 'up')
        this.filter_method = '从旧到新'
      if (command == 'down')
        this.filter_method = '从新到旧'
      this.getArticleList()
    },
    handleSizeChange(newSize) {
      //当每页最多显示的数据数表单更改时，其值(作为参数传给服务器)也要跟着改
      this.queryInfo.pagesize = newSize
      //重新向服务器发送新的参数
      this.getArticleList()
    },
    handleCurrentChange(newPage) {
      //及时更改要获取的页数
      this.queryInfo.pagenum = newPage
      this.getArticleList()
    },
    handleDropDownCommand(command) {
      this.queryInfo.pagenum = 1
      if (command === 'default') {
        this.queryInfo.type = ''
        this.filter_type = '文章类型'
      } else {
        this.queryInfo.type = command
        this.filter_type = command
      }
      this.getArticleList()
    },
    addDialogClosed() {
      //对话框在关闭时清空表单，防止下一次打开时还留有上一次的记录
      this.$refs.addFormRef.resetFields()
    },
    addArticle() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/gongtong/add-article',
          this.addForm
        )
        if (res.meta.status !== 201) {
          this.$message.error('添加文章失败!')
        }
        this.$message.success('添加文章成功!')
        this.addDialogVisible = false
        this.getArticleList()
      })
    },
    async uploadFile(e, index) {
      var file = e.target.files[0]
      var param = new FormData()       // 创建form对象     
      param.append('file', file)       // 通过append向form对象添加数据
      var config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      const res = await this.$http.post('/huayin/upload_file', param, config)
      if (index == 1) {
        var quill = this.$refs.addText.quill
      } else if (index == 2) {
        var quill = this.$refs.editText.quill
      }
      var length = quill.getSelection().index
      quill.insertEmbed(length, 'link', { href: 'https://dyapi.dingyang.net/public/files/files/' + res.data.meta.fileName, innerText: file.name }, 'api')
      quill.setSelection(length + file.name.length)

    },
    async showEditDialog(id) {
      const res = await this.$http.get('/gongtong/show-edit-article/' + id)
      if (res.status !== 200) {
        return this.$message.error('查询文章信息失败!')
      }
      this.editForm = res.data.data[0]
      this.editDialogVisible = true
    },
    async showArticleDialog(id) {
      const res = await this.$http.get('/gongtong/show-edit-article/' + id)
      if (res.status !== 200) {
        return this.$message.error('查询文章信息失败!')
      }
      this.editForm = res.data.data[0]
      this.articleDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    editArticleInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        const res = await this.$http.put(
          '/gongtong/edit-article/' + this.editForm.id,
          {
            title: this.editForm.title,
            description: this.editForm.description,
            content: this.editForm.content,
            type: this.editForm.type,
          }
        )
        if (res.status !== 200) {
          return this.$message.error('修改文章信息失败!')
        }

        this.$message.success('修改文章信息成功!')
        this.editDialogVisible = false
        this.getArticleList()
      })
    },
    async removeArticleById(id) {
      const confirmResult = await this.$confirm(
        //若点确定，confirmResult为：字符串confirm，若点取消，confirmResult为：字符串cancle
        '此操作将永久删除该文章, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err) //此操作将错误值也返回给confirmResult

      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const res = await this.$http.delete('/gongtong/delete-article/' + id)
      if (res.status !== 200) {
        return this.$message.error('删除文章失败!')
      }
      this.$message.success('删除文章成功!')
      this.getArticleList()
    },
  },
  components: {
    quillEditor,
  },
}
</script>
<style lang="less" scoped>
.el-dropdown-link {
  display: block;
  cursor: pointer;
  color: #409eff;
}
.el-dropdown {
  display: block;
  float: right;
  height: 40px;
  margin-right: 20px;
  margin-top: 10px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.articleDialog {
  h1 {
    text-align: center;
  }
  line-height: 1.5;
}
</style>