<template>
  <div>
    <el-container>
      <el-header>
        <div class="title">丁洋后台管理系统</div>
      </el-header>
      <el-main>
        <el-card class="main">
          <h3>管理员登录</h3>
          <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px">
            <el-form-item prop="username">
              <el-input v-model="loginForm.username" prefix-icon="el-icon-user-solid" placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="loginForm.password" prefix-icon="el-icon-lock" type="password" placeholder="密  码"></el-input>
            </el-form-item>
            <el-form-item class="btns">
              <el-button type="primary" @click="login" @keydown.enter="login" size="small">登录</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginFormRules: {
        /* 验证用户名是否合法 */
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }, //trigger指提示的时间
        ],
        /* 验证密码是否合法 */
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    login() {
      this.$refs.loginFormRef.validate(async (valid) => {
        //validate对表单进行校验，valid为布尔值返回校验结果
        if (!valid) return
        const { data: res } = await this.$http.post('/login', this.loginForm) //只提取出返回体中的data一项，并将其重命名为res
        if (res.meta.status !== 200) return this.$message.error('用户名或密码错误,登陆失败')
        this.$message.success('登录成功')
        //若登录成功，将获取到的token存储到本地
        window.sessionStorage.setItem('token', res.token)
        //重定向
        this.$router.push('/home')
      })
    }
  }
}
</script>
<style lang="less" scoped>
.el-header {
  height: 100px !important;
}
.title {
  margin: 30px auto;
  width: 320px;
  font-size: 40px;
  font-weight: 500;
}
.main {
  margin: 50px auto;
  width: 50%;
  min-width: 480px;
}
.main h3 {
  width: 90%;
  margin: 0px auto;
  margin-bottom: 20px;
  text-align: center;
  padding: 15px 0;
  border-bottom: 2px solid rgb(255, 174, 61);
}
.el-form {
  margin: auto;
  width: 70%;
}
.el-input {
  margin: 5px 0;
}
.btns {
  text-align: center;
}
.btns .el-button {
  margin: 0 50px;
}
.btns .el-button:nth-child(1) {
  background-color: rgb(22, 143, 72);
  width: 200px;
  height: 32px;
}
</style>