<template>
  <div class="welcome_container">
    <div class="welcome_card">
      <p>欢迎使用后台管理系统!</p>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style lang="less" scoped>
.welcome_container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.welcome_card {
  display: flex;
  width: 90%;
  height: 90%;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  p {
    font-size: 60px;
  }
}
</style>
