<template>
  <el-container class="home">
    <el-header>
      <div>
        <img src="../assets/Logo.svg" alt="" class="logo">
        <el-link href="/" :underline="false">后台管理系统</el-link>
      </div>
      <el-button type="text" @click="logout">退出</el-button>
      <el-button type="text" @click="dialogVisible = true">修改密码</el-button>
      <a href="https://gt.dingyang.net/" target="_blank">访问共同基金网站</a>
      <a href="https://hy.dingyang.net" target="_blank">访问华银网站</a>
      <a href="https://dingyang.onrender.com" target="_blank">访问丁洋网站</a>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <el-menu background-color="#333744" text-color="#fff" active-text-color="#409eff" unique-opened :collapse-transition="false" router :default-active="activePath">
          <el-submenu index="1" :key="1">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>丁洋管理</span>
            </template>
            <el-menu-item-group>
              <!-- <el-menu-item index="/dingyang_article" @click="saveNavStatus('/dingyang_article')">文章管理</el-menu-item> -->
              <el-menu-item index="/dingyang_article_new" @click="saveNavStatus('/dingyang_article_new')">文章管理</el-menu-item>
              <el-menu-item index="/dingyang_page" @click="saveNavStatus('/dingyang_page')">页面管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2" :key="2">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>华银管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/huayin_article" @click="saveNavStatus('/huayin_article')">文章管理</el-menu-item>
              <!-- <el-menu-item index="/huayin_news" @click="saveNavStatus('/huayin_news')">新闻管理</el-menu-item> -->
              <el-menu-item index="/huayin_page" @click="saveNavStatus('/huayin_page')">页面管理</el-menu-item>
              <!-- <el-menu-item index="/huayin_image" @click="saveNavStatus('/huayin_image')">图片管理</el-menu-item> -->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3" :key="3">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>共同基金管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/gongtong_article" @click="saveNavStatus('/gongtong_article')">文章管理</el-menu-item>
              <el-menu-item index="/gongtong_page" @click="saveNavStatus('/gongtong_page')">页面管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4" :key="4">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>多媒体管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/video" @click="saveNavStatus('/video')">视频管理</el-menu-item>
              <el-menu-item index="/image" @click="saveNavStatus('/image')">图片管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <router-view></router-view>
          <el-dialog title="修改密码" :visible.sync="dialogVisible" width="70%" @close="dialogClosed">
            <!-- 主题内容区域 -->
            <el-form ref="formRef" :model="form" :rules="formRules" label-width="150px">
              <el-form-item label="请输入新的密码" prop="passwd">
                <el-input v-model="form.passwd"></el-input>
              </el-form-item>
              <el-form-item label="请再次输入新的密码" prop="passwd2">
                <el-input v-model="form.passwd2"></el-input>
              </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="changePasswd">确 定</el-button>
            </span>
          </el-dialog>
        </el-main>
        <el-footer>
          <div>Dingyang.net</div>
          <div>Redesign Demos - Dingyang | 2022 © Grant Howard</div>
        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      activePath: '',
      form: {
        passwd: '',
        passwd2: '',
      },
      dialogVisible: false,
      formRules: {
        passwd: [{ required: true, message: '请输入新的密码', trigger: 'blur' }],
        passwd2: [{ required: true, message: '请再次输入新的密码', trigger: 'blur' }],
      },
    }
  },
  methods: {
    saveNavStatus(activePath) {
      //通过参数接收正在被选中的路径，并存到sessionStorage中
      window.sessionStorage.setItem('activePath', activePath)
      //在每次点击时也要时刻更新activePath
      this.activePath = activePath
    },
    logout() {
      //清空session登录状态
      window.sessionStorage.clear()
      //重定向到登录界面
      this.$router.push('/login')
    },
    dialogClosed() {
      //对话框在关闭时清空表单，防止下一次打开时还留有上一次的记录
      this.$refs.formRef.resetFields()
    },
    async changePasswd() {
      if (this.form.passwd !== this.form.passwd2) {
        this.$message.error('前后输入的密码不一致!')
        return
      } else {
        const { data: res } = await this.$http.post('/changepasswd', { newpasswd: this.form.passwd })
        if (res.meta.status !== 200) {
          this.$message.error('修改密码失败!')
        }
        this.$message.success('修改密码成功! 请重新登陆!')
        this.dialogVisible = false
        this.logout()
      }

    }
  },
}
</script>

<style lang="less" scoped>
.home {
  height: 100%;
}
.logo {
  height: 80%;
  vertical-align: middle;
}
.el-header {
  // display: flex;
  height: 60px;
  min-width: 900px;
  // align-items: center;
  // justify-content: space-between;
  background-color: #373d41;
  color: white;
}
.el-header > div {
  // display: flex;
  float: left;
  height: 100%;
  line-height: 57px;
  // align-items: center;
  .el-link {
    color: white;
    margin-left: 15px;
    font-size: 30px;
    font-weight: 500;
  }
}
.el-header > .el-button {
  float: right;
  display: block;
  margin-left: 30px;
  color: rgb(192, 192, 192);
  line-height: 36px;
  font-size: 17px;
  font-weight: 700;
}
.el-header > .el-button:hover {
  color: white;
}

.el-header > a {
  float: right;
  display: block;
  margin: 0 10px;
  color: rgb(192, 192, 192);
  line-height: 60px;
  font-size: 17px;
  text-decoration: none;
}

.el-header > a:hover {
  color: white;
}

.el-aside {
  background-color: #333744;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  min-width: 700px;
}
.el-footer {
  display: flex;
  min-width: 700px;
  align-items: center;
  justify-content: space-between;
  background-color: #373d41;
}
.el-footer div:nth-child(1) {
  font-size: 20px;
  font-weight: 500;
  color: rgb(202, 195, 195);
}

.el-footer div:nth-child(2) {
  color: rgb(202, 195, 195);
}
</style>
