<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>丁洋管理</el-breadcrumb-item>
      <el-breadcrumb-item>文章管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <!-- 使用24分栏进行布局 -->
        <el-col :span="8">
          <!-- clearable为清空按钮 -->
          <!-- clear为点击清空按钮后要实现的函数 -->
          <el-input placeholder="请输入内容" v-model="queryInfo.title" clearable @clear="getArticleList">
            <el-button slot="append" icon="el-icon-search" @click="getArticleList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" @click="addDialogVisible = true">添加文章</el-button>
        </el-col>
        <el-dropdown @command="dropdownCommand">
          <span class="el-dropdown-link">排序方式<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="default">默认排序</el-dropdown-item>
            <el-dropdown-item command="up">按时间从旧到新排序</el-dropdown-item>
            <el-dropdown-item command="down">按时间从新到旧排序</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
      <el-table :data="articleList" border stripe>
        <!-- 添加索引列 -->
        <el-table-column label="id" prop="id" width="60px"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="简介" prop="description"></el-table-column>
        <el-table-column label="类型" prop="type"></el-table-column>
        <el-table-column label="创建时间" prop="create_at"></el-table-column>
        <el-table-column label="更新时间" prop="update_at"></el-table-column>
        <el-table-column label="操作" width="200px">
          <template slot-scope="scope">
            <el-button type="info" icon="el-icon-zoom-in" size="mini" @click="showArticleDialog(scope.row.id)"></el-button>
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeArticleById(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[10,15,30]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <el-dialog title="添加文章" :visible.sync="addDialogVisible" width="70%" @close="addDialogClosed">
      <!-- 主题内容区域 -->
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="description">
          <el-input v-model="addForm.description"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="addForm.type" placeholder="请选择文章类型">
            <el-option label="今日预测" value="今日预测"></el-option>
            <el-option label="操盘精要" value="操盘精要"></el-option>
            <el-option label="实时播报" value="实时播报"></el-option>
            <el-option label="股市人生" value="股市人生"></el-option>
            <el-option label="实地调研" value="实地调研"></el-option>
            <el-option label="投资哲学" value="投资哲学"></el-option>
            <el-option label="丁洋简介" value="丁洋简介"></el-option>
            <el-option label="技术精华" value="技术精华"></el-option>
            <el-option label="明日预测" value="明日预测"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="正文" prop="content">
          <quillEditor ref="addText" v-model="addForm.content"></quillEditor>
        </el-form-item>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addArticle">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="修改文章" :visible.sync="editDialogVisible" width="70%" @close="editDialogClosed">
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="editForm.title"></el-input>
        </el-form-item>
        <el-form-item label="简介" prop="introduction">
          <el-input v-model="editForm.description"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="editForm.type" placeholder="请选择文章类型">
            <el-option label="今日预测" value="今日预测"></el-option>
            <el-option label="操盘精要" value="操盘精要"></el-option>
            <el-option label="实时播报" value="实时播报"></el-option>
            <el-option label="股市人生" value="股市人生"></el-option>
            <el-option label="实地调研" value="实地调研"></el-option>
            <el-option label="投资哲学" value="投资哲学"></el-option>
            <el-option label="丁洋简介" value="丁洋简介"></el-option>
            <el-option label="技术精华" value="技术精华"></el-option>
            <el-option label="明日预测" value="明日预测"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="正文" prop="text">
          <quillEditor ref="editText" v-model="editForm.content"></quillEditor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editArticleInfo">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="查看文章" :visible.sync="articleDialogVisible" width="70%" class="articleDialog">
      <!-- 主题内容区域 -->
      <h1 v-html="editForm.title"></h1>
      <div v-html="$xss(editForm.content)"></div>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="articleDialogVisible=false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  data() {
    return {
      queryInfo: {
        title: '',
        type: '',
        pagenum: 1,
        pagesize: 15,
        sort: 'default',
      },
      articleList: [],
      total: 0,
      addDialogVisible: false,
      addForm: {
        title: '',
        description: '',
        type: '',
        content: '',
        website: 'dingyang',
      },
      addFormRules: {
        title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
        description: [
          { required: false, message: '请输入文章简介', trigger: 'blur' },
        ],
        type: [{ required: true, message: '请输入文章类型', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入文章正文', trigger: 'blur' },
        ],
      },
      editForm: {},
      editDialogVisible: false,
      articleDialogVisible: false,
      editFormRules: {
        title: [{ required: true, message: '请输入文章标题', trigger: 'blur' }],
        description: [
          { required: false, message: '请输入文章简介', trigger: 'blur' },
        ],
        type: [{ required: true, message: '请输入文章类型', trigger: 'blur' }],
        content: [
          { required: true, message: '请输入文章正文', trigger: 'blur' },
        ],
      },
    }
  },
  created() {
    this.getArticleList()
  },
  methods: {
    async getArticleList() {
      const res = await this.$http.get('/dingyang/get-articles-by-time', {
        params: this.queryInfo,
      })
      this.articleList = res.data.data
      const total_server = await this.$http.get('/dingyang/get-articles-count')
      this.total = total_server.data.data[0]['COUNT(*)']
    },
    dropdownCommand(command) {
      this.queryInfo.sort = command
      this.getArticleList()
    },
    handleSizeChange(newSize) {
      //当每页最多显示的数据数表单更改时，其值(作为参数传给服务器)也要跟着改
      this.queryInfo.pagesize = newSize
      //重新向服务器发送新的参数
      this.getArticleList()
    },
    handleCurrentChange(newPage) {
      //及时更改要获取的页数
      this.queryInfo.pagenum = newPage
      this.getArticleList()
    },
    addDialogClosed() {
      //对话框在关闭时清空表单，防止下一次打开时还留有上一次的记录
      this.$refs.addFormRef.resetFields()
    },
    addArticle() {
      this.$refs.addFormRef.validate(async (valid) => {
        if (!valid) return
        const { data: res } = await this.$http.post(
          '/dingyang/add-article',
          this.addForm
        )
        if (res.meta.status !== 201) {
          this.$message.error('添加文章失败!')
        }
        this.$message.success('添加文章成功!')
        this.addDialogVisible = false
        this.getArticleList()
      })
    },
    async showArticleDialog(id) {
      const res = await this.$http.get('/dingyang_new/show-edit-article/' + id)
      if (res.status !== 200) {
        return this.$message.error('查询文章信息失败!')
      }
      this.editForm = res.data.data[0]
      this.articleDialogVisible = true
    },
    async showEditDialog(id) {
      const res = await this.$http.get('/dingyang/show-edit-article/' + id)
      if (res.status !== 200) {
        return this.$message.error('查询文章信息失败!')
      }
      this.editForm = res.data.data[0]
      this.editDialogVisible = true
    },
    editDialogClosed() {
      this.$refs.editFormRef.resetFields()
    },
    editArticleInfo() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) return
        const res = await this.$http.put(
          '/dingyang/edit-article/' + this.editForm.id,
          {
            title: this.editForm.title,
            description: this.editForm.description,
            content: this.editForm.content,
            type: this.editForm.type,
          }
        )
        if (res.status !== 200) {
          return this.$message.error('修改文章信息失败!')
        }

        this.$message.success('修改文章信息成功!')
        this.editDialogVisible = false
        this.getArticleList()
      })
    },
    async removeArticleById(id) {
      const confirmResult = await this.$confirm(
        //若点确定，confirmResult为：字符串confirm，若点取消，confirmResult为：字符串cancle
        '此操作将永久删除该文章, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err) //此操作将错误值也返回给confirmResult

      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const res = await this.$http.delete('/dingyang/delete-article/' + id)
      if (res.status !== 200) {
        return this.$message.error('删除文章失败!')
      }
      this.$message.success('删除文章成功!')
      this.getArticleList()
    },
  },
  components: {
    quillEditor,
  },
}
</script>
<style lang="less" scoped>
.el-dropdown-link {
  display: block;
  cursor: pointer;
  color: #409eff;
}
.el-dropdown {
  display: block;
  float: right;
  height: 40px;
  margin-right: 20px;
  margin-top: 10px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.articleDialog {
  h1 {
    text-align: center;
  }
  line-height: 1.5;
}
</style>