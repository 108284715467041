import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
import xss from 'xss'

/* 导入全局样式 */
import './assets/css/global.css'

import axios from 'axios'
import { Quill } from 'vue-quill-editor'

// 自定义插入a链接
var Link = Quill.import('formats/link')
class FileBlot extends Link {
  // 继承Link Blot
  static create(value) {
    let node = undefined
    if (value && !value.href) {
      // 适应原本的Link Blot
      node = super.create(value)
    } else {
      // 自定义Link Blot
      node = super.create(value.href)
      // node.setAttribute('download', value.innerText);  // 左键点击即下载
      node.innerText = value.innerText
      node.download = value.innerText
    }
    return node
  }
}
FileBlot.blotName = 'link'
FileBlot.tagName = 'A'
Quill.register(FileBlot)

//设置页面标题
document.title = '丁洋后台管理系统'

//配置请求的根路径
axios.defaults.baseURL = 'https://dyapi.dingyang.net/api'

//配置请求拦截器,除了登录请求外,在请求头中必须加入有效token才能获得有效数据返回
axios.interceptors.request.use(config => {
  config.headers.Authorization = window.sessionStorage.getItem('token') //将本地token放在请求头中
  return config
})

Vue.prototype.$http = axios

Vue.config.productionTip = false

Object.defineProperty(Vue.prototype, '$xss', {
  value: xss
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
