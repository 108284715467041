<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>多媒体管理</el-breadcrumb-item>
      <el-breadcrumb-item>视频管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <!-- 使用24分栏进行布局 -->
        <el-col :span="8">
          <!-- clearable为清空按钮 -->
          <!-- clear为点击清空按钮后要实现的函数 -->
          <el-input placeholder="请输入内容" v-model="queryInfo.title" clearable @clear="getVideoList">
            <el-button slot="append" icon="el-icon-search" @click="getVideoList">搜索</el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible = true">添加视频</el-button>
        </el-col>
      </el-row>
      <el-table :data="videoList" border stripe>
        <!-- 添加索引列 -->
        <el-table-column label="id" prop="id" width="60px"></el-table-column>
        <el-table-column label="标题" prop="title"></el-table-column>
        <el-table-column label="视频大小(MB)" prop="size">
          <template slot-scope="scope">
            <div v-html="(scope.row.size/1024/1024).toFixed(2)"></div>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="create_at"></el-table-column>
        <el-table-column label="播放地址">
          <template slot-scope="scope">
            <a :href="'https://dyapi.dingyang.net'+scope.row.location" target="_blank">播放地址</a>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeVideoById(scope.row.id,scope.row.name)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum" :page-sizes="[5, 10]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>

    <el-dialog title="添加视频" :visible.sync="addDialogVisible" width="70%" @close="addDialogClosed">
      <!-- 主题内容区域 -->
      <el-form ref="addFormRef" :model="addForm" :rules="addFormRules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="addForm.title"></el-input>
        </el-form-item>
        <el-upload class="upload-demo" ref="uploadFormRef" drag :action="'https://dyapi.dingyang.net/api/dingyang/upload_video/'+addForm.title" :multiple="false" v :limit="1" name="video" :auto-upload="false" :on-success="uploadSuccess">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">上传的文件名必须为英文且其中没有空格</div>
        </el-upload>
      </el-form>
      <!-- 底部区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVideo">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
export default {
  data() {
    return {
      queryInfo: {
        title: '',
        name: '',
        pagenum: 1,
        pagesize: 5,
      },
      videoList: [],
      total: 0,
      addDialogVisible: false,
      addForm: {
        title: '',
      },
      addFormRules: {
        title: [{ required: true, message: '请输入视频标题', trigger: 'blur' }],
      },
      upload_headers: {
        'content-type': 'multipart/form-data',
      },
    }
  },
  created() {
    this.getVideoList()
  },
  methods: {
    async getVideoList() {
      const res = await this.$http.get('/dingyang/get-videos', {
        params: this.queryInfo,
      })
      this.videoList = res.data.data
      const total_server = await this.$http.get('/dingyang/get-videos-count')
      this.total = total_server.data.data[0]['COUNT(*)']
    },
    handleSizeChange(newSize) {
      //当每页最多显示的数据数表单更改时，其值(作为参数传给服务器)也要跟着改
      this.queryInfo.pagesize = newSize
      //重新向服务器发送新的参数
      this.getVideoList()
    },
    handleCurrentChange(newPage) {
      //及时更改要获取的页数
      this.queryInfo.pagenum = newPage
      this.getVideoList()
    },
    addDialogClosed() {
      //对话框在关闭时清空表单，防止下一次打开时还留有上一次的记录
      this.$refs.addFormRef.resetFields()
      this.$refs.uploadFormRef.clearFiles()
    },
    addVideo() {
      this.$refs.uploadFormRef.submit()
    },
    uploadSuccess() {
      setTimeout((this.addDialogVisible = false), 3000)
      this.getVideoList()
    },
    async removeVideoById(id, name) {
      const confirmResult = await this.$confirm(
        //若点确定，confirmResult为：字符串confirm，若点取消，confirmResult为：字符串cancle
        '此操作将永久删除该视频, 是否继续?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err) //此操作将错误值也返回给confirmResult
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const res = await this.$http.delete(
        '/dingyang/delete-video/' + id + '/' + name
      )
      if (res.status !== 200) {
        return this.$message.error('删除视频失败!')
      }
      this.$message.success('删除视频成功!')
      this.getVideoList()
    },
  },
}
</script>
<style lang="less" scoped>
.upload-demo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>