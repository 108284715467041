import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Welcome from '../views/Welcome.vue'
import Login from '../views/Login.vue'
import DingYangArticle from '../views/articles/DingYangArticle.vue'
import DingYangArticleNew from '../views/articles/DingYangArticleNew.vue'
import Video from '../views/videos/Video.vue'
import HuaYinArticle from '../views/articles/HuaYinArticle.vue'
import HuaYinNews from '../views/news/HuaYinNews.vue'
import DingYangPage from '../views/pages/DingYangPage.vue'
import HuaYinPage from '../views/pages/HuaYinPage.vue'
import Image from '../views/images/Image.vue'
import GongTongArticle from '../views/articles/GongTongArticle.vue'
import GongTongPage from '../views/pages/GongTongPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/home', component: Home, redirect: '/welcome', children: [
      { path: '/welcome', component: Welcome },
      { path: '/dingyang_article', component: DingYangArticle },
      { path: '/dingyang_article_new', component: DingYangArticleNew },
      { path: '/video', component: Video },
      { path: '/dingyang_page', component: DingYangPage },
      { path: '/huayin_article', component: HuaYinArticle },
      { path: '/huayin_news', component: HuaYinNews },
      { path: '/huayin_page', component: HuaYinPage },
      { path: '/image', component: Image },
      { path: '/gongtong_article', component: GongTongArticle },
      { path: '/gongtong_page', component: GongTongPage }
    ]
  }
]

const router = new VueRouter({
  routes
})

/* 挂载路由导航守卫 */
router.beforeEach((to, from, next) => {
  //to为将要访问的路径
  if (to.path === '/login') return next()
  //获取token
  const tokenStr = window.sessionStorage.getItem('token')
  //如果没有token(就是没登录，用next函数强制跳转到登录页)
  if (!tokenStr) return next('/login')
  //如果有token，直接调用next函数放行
  next()
})

export default router
